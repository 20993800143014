import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Select from "react-select";
import { botApis } from "../services/botApis";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling, formatBalance } from "../Common/CommonComponents";
import { useNavigate } from "react-router-dom";
import ManualBuy from "./ManualBuy";
import ManualSell from "./ManualSell";
import Loader from "../Common/Loader";
import { connectionWeb3 } from "../config/solanaWeb3";
import Swal from "sweetalert2";
import NewToken from "../Common/NewToken";
import TransactionLog from "../Common/TransactionLog";
import RPCURL from "../Common/RPCURL";
import moment from "moment";

const Home = () => {
    const navigate = useNavigate();
    const [manualSellData, setManualSellData] = useState({ amountPercentage: 0, tokenAddress: "", errors: {} });
    const [showManualBuy, setShowManualBuy] = useState(false);
    const [showManualSell, setShowManualSell] = useState(false);
    const [copied, setCopied] = useState({});
    const [telegramConnectedStatus, setTelegramConnectedStatus] = useState(false);
    const [buyBotStatus, setBuyBotStatus] = useState(false);
    const [sellBotStatus, setSellBotStatus] = useState(false);
    const [newTokenStatus, setNewTokenStatus] = useState(false)
    const [showAddRpcModal, setShowAddRpcModal] = useState(false);
    const [latestTelegramMessages, setLatestTelegramMessages] = useState([]);
    const [telegramNextPage, setTelegramNextPage] = useState(0);
    const telegramMessagesRef = useRef();
    const [isCallTelegramObserverTop, setIsCallTelegramObserverTop] = useState(false);
    const telegramMessagesObserverTop = useRef();
    const isCallTelegramObserverTopRef = useRef(false);
    const [latestTokens, setLatestTokens] = useState([]);
    const [nextPage, setNextPage] = useState(0);
    const tokenLogsRef = useRef();
    const [isCallObserverTop, setIsCallObserverTop] = useState(false);
    const tokenLogsObserverTop = useRef();
    const isCallObserverTopRef = useRef(false);
    const [adminData, setAdminData] = useState({});
    const [error, setError] = useState({});
    const [latestTransactions, setLatestTransactions] = useState([]);
    const [latestTransactionsNextPage, setLatestTransactionsNextPage] = useState(0);
    const latestTransactionsRef = useRef();
    const [isCallLatestTransactionsObserverTop, setIsCallLatestTransactionsObserverTop] = useState(false);
    const latestTransactionsObserverTop = useRef();
    const isCallLatestTransactionsObserverTopRef = useRef(false);
    const [boughtTokens, setBoughtTokens] = useState([]);
    const [boughtStatus, setBoughtStatus] = useState(true)
    const [boughtTokensNextPage, setBoughtTokensNextPage] = useState(0);
    const boughtTokensRef = useRef();
    const [isCallBoughtTokensObserverTop, setIsCallBoughtTokensObserverTop] = useState(false);
    const boughtTokensObserverTop = useRef();
    const isCallBoughtTokensObserverTopRef = useRef(false);
    const { getBalance } = connectionWeb3();
    const balanceTimeOutRef = useRef(0);
    const [inProcess, setInProcess] = useState(false);
    const [serverBotStatus, setServerBotStatus] = useState(false);
    const adminDataRef = useRef(0);
    const [sellOptionStatus, setSellOptionStatus] = useState(false)

    useEffect(() => {
        init();
        getAccountdetail()
        if (!localStorage.getItem("access_token")) {
            window.location.href = "/"
        }
    }, [])

    /* start of the Account detail functinality code */
    /* get account detail */
    const getAccountdetail = async () => {
        try {
            const response = await botApis.getAccountDetails();
            if (response.status === 200) {
                const responseData = { ...response?.data?.data?.user };
                setAdminData(responseData);
                adminDataRef.current = responseData;
                setBuyBotStatus(response?.data?.data?.user?.auto_buy)
                setNewTokenStatus(response?.data?.data?.user?.newly_token_status)
                setSellBotStatus(response?.data?.data?.user?.auto_sell)
                setLpLockStatus(response?.data?.data?.user?.lp_locked);
            } else {
                console.log("Failed to fetch data------------>")
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    /* sell option */
    const handleStageChange = (stage, field, value) => {
        setSellOptionStatus(false)
        setAdminData({
            ...adminData,
            [stage]: { ...adminData[stage], [field]: value }
        });
    };


    /* Account detail save */
    async function updateUser(type, BuyStatus) {
        setUserLoader(false);

        try {
            let errorTemp = { ...error }, status = false;
            if (!adminData.wallet_address || !adminData.wallet_address.trim()) {
                status = true;
                errorTemp.wallet_address = "Wallet address is required";
            }
            if (!adminData.wallet_private_key || !adminData.wallet_private_key.trim()) {
                status = true;
                errorTemp.wallet_private_key = "Wallet private key is required";
            }

            if (!adminData.max_lamports || (adminData.max_lamports && adminData.max_lamports <= 0)) {
                status = true;
                errorTemp.max_lamports = "Max Lamports is required";
            }
            if (!adminData.units || (adminData.units && adminData.units <= 0)) {
                status = true;
                errorTemp.units = "Units is required";
            }

            if (!adminData.slippage || (adminData.slippage && adminData.slippage <= 0)) {
                status = true;
                errorTemp.slippage = "Slippage is required";
            }

            if (!adminData?.minimum_liquidity) {
                status = true;
                errorTemp.minimum_liquidity = "Minimum liquidity is required";
            }

            if (status) {
                setError(errorTemp);
                return;
            }

            const params = {
                wallet_address: adminData.wallet_address,
                wallet_private_key: adminData.wallet_private_key,
                max_lamports: Number(adminData.max_lamports),
                units: Number(adminData.units),
                slippage: Number(adminData.slippage),
                minimum_liquidity: Number(adminData?.minimum_liquidity),
            }
            const response = await botApis.updateUser(params);
            if (response.status === 200) {
                setAdminData({ ...response?.data?.data?.user });
                getBoughtTokens(nextPage, "TOP")
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            setUserLoader(false);
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    /* end of the Account detail functinality code */

    /* Buy save */
    async function updateUserBuy(type) {
        setUserLoader(false);
        try {
            const params = {
                token_buy_amount: adminData?.token_buy_amount,
            }
            const response = await botApis.updateUser(params);
            if (response.status === 200) {
                setAdminData({ ...adminData, token_buy_amount: response?.data?.data?.user.token_buy_amount });
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            setUserLoader(false);
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    /* sell validation */
    const validateStages = () => {
        let totalSellPercentage = 0;
        let tokenPricePercentage = 0;
        ['profit_stage1', 'profit_stage2', 'profit_stage3'].forEach(stage => {
            totalSellPercentage += parseFloat(adminData[stage].token_sell_percentage) || 0;
            // tokenPricePercentage += parseFloat(adminData[stage].token_price_percentage) || 0;
        });

        // if (totalSellPercentage > 100 || tokenPricePercentage >100) {
        if (totalSellPercentage > 100) {
            setError(prevError => ({
                ...prevError,
                general: 'The sum of token sell percentages/ token price percentages should not exceed 100.'
            }));
            setSellOptionStatus(true)
            return false;
        }
        return true;
    };

    /* sell options save */

    async function updateUserSellOption(type) {
        if (!validateStages()) {
            return;
        }
        setUserLoader(false);
        try {
            const params = {
                profit_stage1: adminData.profit_stage1,
                profit_stage2: adminData.profit_stage2,
                profit_stage3: adminData.profit_stage3,
                loss_percentage: adminData?.loss_percentage

            }
            const response = await botApis.updateUser(params);
            if (response.status === 200) {
                const { profit_stage1, profit_stage2, profit_stage3, loss_percentage } = response?.data?.data?.user;
                setAdminData({
                    ...adminData,
                    profit_stage1,
                    profit_stage2,
                    profit_stage3,
                    loss_percentage
                });
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            setUserLoader(false);
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }



    useEffect(() => {
        const interval = setInterval(() => {
            if (telegramConnectedStatus) {
                setIsCallObserverTop(false);
                isCallObserverTopRef.current = false;
                setIsCallTelegramObserverTop(false);
                isCallTelegramObserverTopRef.current = false;
            }
            if (buyBotStatus) {
                isCallLatestTransactionsObserverTopRef.current = false;
                setIsCallLatestTransactionsObserverTop(false);

                isCallBoughtTokensObserverTopRef.current = false;
                setIsCallBoughtTokensObserverTop(false);
            }
            checkBalance();
        }, 5000);
        return () => clearInterval(interval);
    }, [buyBotStatus]);



    async function checkBalance() {
        if (adminDataRef.current?.wallet_address && balanceTimeOutRef.current == 0) {
            const balance = await getBalance(adminDataRef.current.wallet_address);
            setAdminData({ ...adminDataRef.current, balance: balance, balanceStatus: true });
        }
        if (balanceTimeOutRef.current == 90000) {
            balanceTimeOutRef.current = 0;
        }
        balanceTimeOutRef.current = balanceTimeOutRef.current + 5000;
    }

    async function init() {
        checkServerBotConnection();
        checkTelegramConnection();
        // checkBuyBotConnection();
        getLatestTokens(0, "TELEGRAM_TOP");
        getLatestTokens(0, "TOP");
        getLatestTransactions(0, "TOP");
        getBoughtTokens(0, "TOP");
    }

    

    const [buyOptionLoader, setBuyOptionLoader] = useState(false);
    const [sellOptionLoader, setSellOptionLoader] = useState(false);
    const [userLoader, setUserLoader] = useState(false);




    async function checkTelegramConnection() {
        try {
            const response = await botApis.checkTelegramConnection();
            if (response.status === 200) {
                setTelegramConnectedStatus(response.data.status);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    

    const [lpLockStatus, setLpLockStatus] = useState(false);

    /* start buy */

    async function startBuyBotConnection(status, type) {
        try {
            const params = {
                [type === "lp_locked" ?  "lp_locked": type === "Buy" ? "auto_buy" : "auto_sell"]: status
            }
            const response = await botApis.updateUser(params)
            if (response.status === 200) {
                setBuyBotStatus(response?.data?.data?.user?.auto_buy);
                setSellBotStatus(response?.data?.data?.user?.auto_sell);
                setLpLockStatus(response?.data?.data?.user?.lp_locked);
            } else {
                throw new Error('Failed to fetch data');
            }
        }
        catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    /* stop busy */

    async function stopBuyBotConnection(status, type) {
        try {
            const params = {
                [type === "lp_locked" ?  "lp_locked": type === "Buy" ? "auto_buy" : "auto_sell"]: status
            }
            const response = await botApis.updateUser(params)
            if (response.status === 200) {
                setBuyBotStatus(response?.data?.data?.user?.auto_buy)
                setSellBotStatus(response?.data?.data?.user?.auto_sell)
                setLpLockStatus(response?.data?.data?.user?.lp_locked);

            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }



    

   

    /* stop sell */

    async function stopSellBotConnection(status) {
        try {
            // const response = await botApis.stopSell();
            const params = {
                auto_sell: status
            }
            const response = await botApis.updateUser(params)
            if (response.status === 200) {
                setSellBotStatus(response?.data?.data?.user?.auto_sell)
                // await checkSellBotConnection();
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    async function getLatestTokens(page, callType) {
        try {
            const response = await botApis.getLatestTokens(page);
            if (response.status === 200) {
                let responseData = response.data;
                if (callType == "BOTTOM" || callType == "TELEGRAM_BOTTOM") {
                    let latestTokensTemp = callType == "BOTTOM" ? [...latestTokens.data] : [...latestTelegramMessages.data];
                    latestTokensTemp.push(...responseData.data);
                    responseData.data = latestTokensTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0);
                }
                if (callType == "TELEGRAM_TOP") {
                    setTelegramNextPage(0);
                }
                if (callType == "BOTTOM" || callType == "TOP") {
                    setLatestTokens(responseData);
                } else {
                    setLatestTelegramMessages(responseData);
                }
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }


    async function getLatestTransactions(page, callType) {
        try {
            const response = await botApis.getLatestTransactions(page);
            if (response.status === 200) {
                let responseData = response.data;
                if (callType == "BOTTOM") {
                    let latestTransactionsTemp = [...latestTransactions.data];
                    latestTransactionsTemp.push(...responseData.data);
                    responseData.data = latestTransactionsTemp;
                }
                if (callType == "TOP") {
                    setLatestTransactionsNextPage(0);
                }
                setLatestTransactions(responseData);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }


    async function getBoughtTokens(page, callType) {
        try {
            const response = await botApis.getBoughtTokens(page);
            if (response.status === 200) {
                let responseData = response.data;

                if (callType == "BOTTOM") {
                    let boughtTokensTemp = [...boughtTokens.data];
                    boughtTokensTemp.push(...responseData);
                    responseData.data = boughtTokensTemp;
                }
                if (callType == "TOP") {
                    setBoughtTokensNextPage(0);
                }
                setBoughtTokens(responseData);
                setBoughtStatus(false)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }


    function sellTokenData(amount, tokenAddress) {
        setManualSellData({ amount, tokenAddress, sellBy: "HOME", amountPercentage: 100 });
        setShowManualSell(true);
    }


    function getTransactions() {
        getLatestTransactions(0, "TOP");
        getBoughtTokens(0, "TOP");

    }

   
    


    async function switchBuyBot(isConnected, type) {
        if (isConnected) {
            await stopBuyBotConnection(!isConnected, type);
        } else {
            await startBuyBotConnection(!isConnected, type);
        }
    }

    /* newly */

    async function switchNewlyBot(isConnected) {
        if (isConnected) {
            await stopNewlyBotConnection(!isConnected);
        } else {
            await startNewlyBotConnection(!isConnected);
        }
    }

    /* stopNewly */

    async function stopNewlyBotConnection(status) {
        try {
            setNewTokenStatus(status);
            let response = await botApis.stopNewlyToken();
            if (response.status === 200) {
                setNewTokenStatus(response?.data?.data?.user?.newly_token_status)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            setNewTokenStatus(!status);
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

    /* start newly */

    async function startNewlyBotConnection(status) {
        try {
            const params = {
            }
            setNewTokenStatus(status);
            const response = await botApis.startNewlyToken(params)
            if (response.status === 200) {
                setNewTokenStatus(response?.data?.data?.newly_token_status)
            } else {
                throw new Error('Failed to fetch data');
            }
        }
        catch (error) {
            setNewTokenStatus(!status);
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }




    const copyTokenAddress = (token, index) => {
        navigator.clipboard.writeText(token)
            .then(() => {
                setCopied({ ...copied, [index]: true });
                setTimeout(() => setCopied({ ...copied, [index]: false }), 2000); // Reset copied state after 2 seconds
            })
            .catch((error) => console.error('Failed to copy:', error));
    };




    useEffect(() => {
        tokenLogsObserverTop.current = new IntersectionObserver(handleTokenLogsObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (latestTokens?.data?.length > 0) {
            tokenLogsObserverTop.current.observe(document.querySelector('.latest-tokens-logs-top'));
        }

    }, [isCallObserverTop, isCallObserverTopRef.current, latestTokens, latestTokens?.data, telegramConnectedStatus]);

    const handleTokenLogsObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallObserverTopRef.current && telegramConnectedStatus) {
            isCallObserverTopRef.current = true;
            setIsCallObserverTop(true);
            await getLatestTokens(0, "TOP");
        }
    };

    


    useEffect(() => {
        telegramMessagesObserverTop.current = new IntersectionObserver(handleTelegramMessagesObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (latestTokens?.data?.length > 0) {
            telegramMessagesObserverTop.current.observe(document.querySelector('.latest-telegram-messages-top'));
        }

    }, [isCallTelegramObserverTop, isCallTelegramObserverTopRef.current, latestTelegramMessages, latestTelegramMessages?.data, telegramConnectedStatus]);

    const handleTelegramMessagesObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallTelegramObserverTopRef.current && telegramConnectedStatus) {
            isCallTelegramObserverTopRef.current = true;
            setIsCallTelegramObserverTop(true);
            await getLatestTokens(0, "TELEGRAM_TOP");
        }
    };

    



    useEffect(() => {
        latestTransactionsObserverTop.current = new IntersectionObserver(handleLatestTransactionsObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (latestTransactions?.data?.length > 0) {
            latestTransactionsObserverTop.current.observe(document.querySelector('.latest-transactions-top'));
        }

    }, [isCallLatestTransactionsObserverTop, isCallLatestTransactionsObserverTopRef.current, latestTransactions, latestTransactions?.data, buyBotStatus]);

    const handleLatestTransactionsObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallLatestTransactionsObserverTopRef.current && buyBotStatus) {
            isCallLatestTransactionsObserverTopRef.current = true;
            setIsCallLatestTransactionsObserverTop(true);
            await getLatestTransactions(0, "TOP");
        }
    };

    


    useEffect(() => {
        boughtTokensObserverTop.current = new IntersectionObserver(handleBoughtTokensObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (boughtTokens?.data?.length > 0) {
            boughtTokensObserverTop.current.observe(document.querySelector('.bought-tokens-top'));
        }

    }, [isCallBoughtTokensObserverTop, isCallBoughtTokensObserverTopRef.current, boughtTokens, boughtTokens?.data, buyBotStatus]);

    const handleBoughtTokensObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallBoughtTokensObserverTopRef.current && buyBotStatus) {
            isCallBoughtTokensObserverTopRef.current = true;
            setIsCallBoughtTokensObserverTop(true);
            await getBoughtTokens(0, "TOP");
        }
    };

    const onScrollBoughtTokens = async () => {
        if (boughtTokensRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = boughtTokensRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = boughtTokensNextPage + 1, totalPages = Number(boughtTokens.total_pages);
                if (page < totalPages) {
                    await getBoughtTokens(page, "BOTTOM");
                    setBoughtTokensNextPage(page);
                }
            }
        }
    };


    const maskAddress = (address, characters = 5) => {
        const startChars = address && address?.substring(0, characters); // Get the first 6 characters
        const endChars = address && address?.substring(address?.length - characters); // Get the last 6 characters
        return `${startChars}.....${endChars}`; // Combine with dots in between
    };

    function convertUtcToLocal(utcTimeString) {
        // Parse the UTC time string
        const utcDate = new Date(utcTimeString);
        return moment(utcDate).format('DD:MM:YYYY, h:mm:ss a')
        // return utcDate.toString()

        // Get the time zone offset in minutes
        const offsetMinutes = utcDate.getTimezoneOffset();

        // Convert UTC time to local time by adding the offset
        const localDate = new Date(utcDate.getTime() - (offsetMinutes * 60000));

        // Format the local time
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true // Include AM/PM indicator
        };
        const formattedLocalTime = localDate.toLocaleString(undefined, options);

        return formattedLocalTime;
    }
    function logout() {
        Swal.fire({
            title: 'Are you sure you want to log out?',
            text: "You will be logged out of your account.",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, log me out'
        }).then((result) => {
            if (result.isConfirmed) {
                localStorage.clear();
                navigate("/", { replace: true });
            }
        });
    }

    

    async function checkServerBotConnection() {
        try {
            const response = await botApis.checkManualPool();
            if (response.status === 200) {
                setServerBotStatus(response.data.status);
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
            console.error('Error fetching data:', error);
        }
    }

   
    




    return (
        <>
            <section className="top-area">
                <Container fluid>
                    <div className="head-top-right">
                        {(adminData?.wallet_address) ?
                            <h6 className="main-wallet-top"> {maskAddress(adminData?.wallet_address)}
                                <img src={require("../assets/images/balance-icon.svg").default} alt="img" />
                                <span>  {adminData?.balance && formatBalance(adminData?.balance)} SOL </span>
                            </h6>
                            : <Skeleton className="main-wallet-top mb-2" height={25} width={200} />}
                        <h6 className="main-wallet-top cursor-pointer" onClick={() => logout()}>
                            <i class="fa fa-sign-out" aria-hidden="true"></i>
                        </h6>
                    </div>
                    <Row>
                        <Col xl={12}>
                            <Row>
                                <Col md={6} lg={6} xl={3} className="mb-3">
                                    <div className="top-boxes">
                                        <Form>
                                            <div className="selling-top-right mb-0">
                                                <h5>Account Detail</h5>
                                                <div className="selling-option-top">
                                                    <h6>Stop</h6>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        value={newTokenStatus}
                                                        checked={newTokenStatus}
                                                        onChange={() => switchNewlyBot(newTokenStatus)} />
                                                    <h6>Start</h6>
                                                </div>
                                            </div>
                                            <Row>
                                                <Col sm={6} md={12} lg={12} className="padding-right">
                                                    <Form.Group controlId="accountPublickey">
                                                        <Form.Label>Account Public key</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Account Public key"
                                                        //  value={adminData?.wallet_address}
                                                          onChange={(e) => setAdminData({ ...adminData, wallet_address: e.target.value })} />
                                                        {(!adminData?.wallet_address || !adminData?.wallet_address.trim()) && error.wallet_address && <p className="error">{error.wallet_address}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6} md={12} lg={12} >
                                                    <Form.Group controlId="accountPrivatekey">
                                                        <Form.Label>Account Private key</Form.Label>
                                                        <Form.Control type="password" placeholder="Enter Account Private key"
                                                        //  value={adminData?.wallet_private_key} 
                                                         onChange={(e) => setAdminData({ ...adminData, wallet_private_key: e.target.value })} />
                                                        {(!adminData?.wallet_private_key || !adminData?.wallet_private_key.trim()) && error.wallet_private_key && <p className="error">{error.wallet_private_key}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6} md={12} lg={12} >
                                                    <Form.Group controlId="units">
                                                        <Form.Label>Units</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter max units" value={adminData?.units} onChange={(e) => setAdminData({ ...adminData, units: e.target.value })} />
                                                        {(!adminData?.units) && error.units && <p className="error">{error.units}</p>}
                                                    </Form.Group>
                                                </Col>

                                                <Col sm={6} md={12} lg={12} >
                                                    <Form.Group controlId="max_lamports">
                                                        <Form.Label>Max Lamports</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter max Lamports" value={adminData?.max_lamports} onChange={(e) => setAdminData({ ...adminData, max_lamports: e.target.value })} />
                                                        {(!adminData?.max_lamports) && error.max_lamports && <p className="error">{error.max_lamports}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6} md={12} lg={12} >
                                                    <Form.Group controlId="slippage">
                                                        <Form.Label>Slippage (Percentage)</Form.Label>
                                                        <Form.Control type="number" min={1} max={100} placeholder="Enter slippage" value={adminData?.slippage} onChange={(e) => setAdminData({ ...adminData, slippage: e.target.value <= 100 ? e.target.value : adminData?.slippage })} />
                                                        {(!adminData?.slippage) && error.slippage && <p className="error">{error.slippage}</p>}
                                                    </Form.Group>
                                                </Col>
                                                <Col sm={6} md={12} lg={12} className="padding-right">
                                                    <Form.Group controlId="minimum_liquidity">
                                                        <Form.Label>Minimum Liquidity Check In SOL</Form.Label>
                                                        <Form.Control type="text" placeholder="Enter Account minimum_liquidity" value={adminData?.minimum_liquidity} maxLength={10} onChange={(e) => setAdminData({ ...adminData, minimum_liquidity: e.target.value })} />
                                                        {(!adminData?.minimum_liquidity || !adminData?.minimum_liquidity) && error?.minimum_liquidity && <p className="error">{error?.minimum_liquidity}</p>}
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                            <Button className="mb-2" type="button" variant="unset" disabled={userLoader}
                                                onClick={() => updateUser("USER")}
                                            >
                                                {userLoader ? <Loader loaderType={"COLOR_RING"} width={15} height={15} /> : "Save"}
                                            </Button>
                                        </Form>
                                    </div>
                                </Col>
                                <Col md={6} lg={6} xl={3} className="mb-3">
                                    <div className="top-boxes selling-top-box">
                                        <Form>
                                            <div className="selling-top-right">
                                                <h5>Buy Option:</h5>
                                                <div className="selling-option-top">
                                                    <h6>Stop</h6>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        value={buyBotStatus}
                                                        checked={buyBotStatus}
                                                        onChange={() => switchBuyBot(buyBotStatus, "Buy")} />
                                                    <h6>Start</h6>
                                                </div>
                                            </div>

                                            <Row>
                                                <Col sm={12} md={12} lg={12}>
                                                    <Form.Group controlId="token_buy_amount " className="mb-2">
                                                        <Form.Label>Amount to Buy (SOL)</Form.Label>
                                                        <Form.Control type="Number" placeholder="Enter Amount" value={adminData?.token_buy_amount} min={0} onChange={(e) => setAdminData({ ...adminData, token_buy_amount: e.target.value })} />
                                                        {(!adminData?.token_buy_amount || adminData?.token_buy_amount == 0) && error.token_buy_amount && <p className="error">{error.token_buy_amount}</p>}
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </Form>

                                        <div className="buttons-bottom mb-2">
                                            <div className="selling-option-top">
                                                <h6>Liquidity Pool Lock</h6>
                                                <Form.Check
                                                    type="checkbox"
                                                    id="custom-checkbox"
                                                    value={lpLockStatus}
                                                    checked={lpLockStatus}
                                                    onChange={() => switchBuyBot(lpLockStatus, "lp_locked")} />
                                            </div>
                                            <Button type="button" variant="unset" onClick={() => setShowManualBuy(true)}>Manual Buy</Button>
                                            <Button type="button" variant="unset" disabled={buyOptionLoader} onClick={() => updateUserBuy("BUY")}>
                                                {buyOptionLoader ? <Loader loaderType={"COLOR_RING"} width={15} height={15} /> : "Save"}
                                            </Button>
                                        </div>

                                        <Row>
                                            <Col md={12} lg={12} xl={12} className="mt-1">
                                                <div className="token-logs-top border-line">
                                                    <h6>RPC Urls</h6>
                                                    <i onClick={() => setShowAddRpcModal(true)} class="fa fa-plus-square" aria-hidden="true"></i>

                                                    {/* <Button variant="primary" className="add-rpc-btn" onClick={()=>setShowAddRpcModal(true)}>
                                                        Add RPC
                                                    </Button> */}
                                                </div>
                                                <RPCURL showAddRpcModal={showAddRpcModal} setShowAddRpcModal={setShowAddRpcModal} maskAddress={maskAddress} copyTokenAddress={copyTokenAddress} copied={copied} convertUtcToLocal={convertUtcToLocal} />
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col md={6} lg={6} xl={3} className="mb-3">
                                    <div className="top-boxes selling-top-box">
                                        <Form>
                                            <div className="selling-top-right">
                                                <h5>Selling Option:</h5>
                                                <div className="selling-option-top">
                                                    <h6>Stop</h6>
                                                    <Form.Check
                                                        type="switch"
                                                        id="custom-switch"
                                                        value={sellBotStatus}
                                                        checked={sellBotStatus}
                                                        // onChange={() => setSellBotStatus(!sellBotStatus)}
                                                        onChange={() => switchBuyBot(sellBotStatus, "Sell")}
                                                    />
                                                    <h6>Start</h6>
                                                </div>
                                            </div>

                                            <Row className="mb-2">
                                                <Col sm={12} md={12} lg={12}>
                                                    <Form.Group controlId="StopLossinPercentage" className="mb-2 selling-inner">
                                                        <Form.Label className="stop-loss-percentage">
                                                            {/* <Form.Check
                                                                type="checkbox"
                                                                id="custom-switch"
                                                                checked={lossPercentageStatus}
                                                                onChange={() => setLossPercentageStatus(!lossPercentageStatus)}
                                                            /> */}
                                                            Stop Loss in Percentage
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            // disabled={!lossPercentageStatus}
                                                            placeholder="Enter Percentage"
                                                            value={adminData?.loss_percentage}
                                                            min={0}
                                                            max={100}
                                                            onChange={(e) =>
                                                                setAdminData({
                                                                    ...adminData,
                                                                    loss_percentage: e.target.value <= 100 ? e.target.value : adminData.loss_percentage
                                                                })
                                                            }
                                                        />
                                                        {(!adminData?.loss_percentage || adminData?.loss_percentage == 0) && error.loss_percentage && <p className="error">{error.loss_percentage}</p>}
                                                    </Form.Group>
                                                </Col>

                                                {['profit_stage1', 'profit_stage2', 'profit_stage3'].map((stage, index) => (
                                                    <Col sm={6} md={12} lg={12} key={index}>
                                                        <Form.Group controlId={`PriceStage${index + 1}`} className="mb-1 selling-inner price-stage">
                                                            <Form.Label>{`Price Stage ${index + 1}`}</Form.Label>
                                                            <Row>
                                                                <Col sm={6}>
                                                                    <Form.Group controlId={`TokenSellPercentage${index + 1}`} className="selling-inner token-sell-percentage">
                                                                        <Form.Label>Token Sell Percentage</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Enter Token Sell Percentage"
                                                                            value={adminData[stage]?.token_sell_percentage}
                                                                            min={0}
                                                                            onChange={(e) =>
                                                                                handleStageChange(stage, 'token_sell_percentage', e.target.value)
                                                                            }
                                                                        />
                                                                        {(!adminData[stage]?.token_sell_percentage || adminData[stage]?.token_sell_percentage == 0) &&
                                                                            error.token_sell_percentage && <p className="error">{error.token_sell_percentage}</p>}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col sm={6}>
                                                                    <Form.Group controlId={`TokenPricePercentage${index + 1}`} className="mb-1 selling-inner token-price-percentage">
                                                                        <Form.Label>Token Price Percentage</Form.Label>
                                                                        <Form.Control
                                                                            type="number"
                                                                            placeholder="Enter Token Price Percentage"
                                                                            value={adminData[stage]?.token_price_percentage}
                                                                            min={0}
                                                                            onChange={(e) =>
                                                                                handleStageChange(stage, 'token_price_percentage', e.target.value)
                                                                            }
                                                                        />
                                                                        {(!adminData[stage]?.token_price_percentage || adminData[stage]?.token_price_percentage == 0) &&
                                                                            error.token_price_percentage && <p className="error">{error.token_price_percentage}</p>}
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Form.Group>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Form>
                                        {sellOptionStatus == true ? <p className="error">{error.general}</p> : ""}
                                        <div className="buttons-bottom mb-2">
                                            <Button type="button" variant="unset" onClick={() => { setManualSellData({ sellBy: "" }); setShowManualSell(true); }}>Manual Sell</Button>
                                            <Button type="button" variant="unset" disabled={sellOptionLoader} onClick={() => updateUserSellOption("SELL")}>
                                                {sellOptionLoader ? <Loader loaderType={"COLOR_RING"} width={15} height={15} /> : "Save"}
                                            </Button>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6} lg={6} xl={3} className="mb-3">
                                    <div className="token-logs top-boxes inner-top-boxes">
                                        <div className="token-logs-top">
                                            <h6>All Token Logs</h6>
                                        </div>
                                        <NewToken maskAddress={maskAddress} copyTokenAddress={copyTokenAddress} copied={copied} convertUtcToLocal={convertUtcToLocal} />
                                    </div>

                                </Col>
                            </Row>

                        </Col>



                    </Row>
                    <Row>
                        <TransactionLog maskAddress={maskAddress} copyTokenAddress={copyTokenAddress} copied={copied} convertUtcToLocal={convertUtcToLocal} />
                        <Col md={12} lg={3} xl={3} className="mb-2">
                            <div className="token-logs top-boxes">
                                <div className="token-logs-top">
                                    <h6>Token Holding</h6>
                                </div>
                                <div className="transaction-logs transaction-body token-mis-area transaction-section-bottom" onScroll={onScrollBoughtTokens} ref={boughtTokensRef}>
                                    <Table >
                                        <thead>
                                            <tr>
                                                <th>Token Address</th>
                                                <th>Balance</th>
                                                <th className="token-score">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <div className="bought-tokens-top"></div>
                                            {
                                                boughtStatus == true
                                                    ? (<tr>
                                                        <td className="token-front "><Skeleton height={15} width={120} count={20} /></td>
                                                        <td className="token-front "><Skeleton height={15} width={100} count={20} /></td>
                                                        <td className="token-front"><Skeleton height={15} width={50} count={20} /></td>
                                                    </tr>) :
                                                    boughtTokens?.data?.tokens?.length > 0 ?
                                                        boughtTokens?.data?.tokens?.map((item, index) => {
                                                            return (
                                                                <tr>
                                                                    <td className="token-front token-address-area">{maskAddress(item.token.address, 10)}
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestTransactions${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                                                        >
                                                                            <i class="far fa-copy" onClick={() => copyTokenAddress(item.token.address, `latestTransactions${index}`)}
                                                                            ></i>
                                                                        </OverlayTrigger>
                                                                        {/* <h6>
                                                                            (<p>Balance:</p>
                                                                            {Number(formatBalance(item.balance)) < 0.01 ? "<0.01" : formatBalance(item.balance)} {item.token.symbol})
                                                                        </h6> */}
                                                                    </td>
                                                                    <td>{(item.balance)} {item.token.symbol}</td>
                                                                    <td className="sell-btn"><Button variant="unset" type="button" onClick={() => sellTokenData(formatBalance(item.balance), item.token.address)}>Sell</Button></td>
                                                                </tr>)
                                                        })
                                                        :
                                                        <>
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    <div className="no-transaction-area">
                                                                        <img src={require("../assets/images/no-data.png")} alt="img" />
                                                                        <p>No Token Holding available.</p>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>
                                            }
                                        </tbody>
                                    </Table>
                                </div>


                            </div>
                        </Col>
                    </Row>
                </Container>
            </section >

            {showManualBuy && <ManualBuy showModal={showManualBuy} closeModal={setShowManualBuy} adminData={adminData} getTransactions={getTransactions} />
            }
            {showManualSell && <ManualSell manualSellData={manualSellData} setManualSellData={setManualSellData} showModal={showManualSell} closeModal={setShowManualSell} adminData={adminData} getTransactions={getTransactions} />}

        </>
    );
};
export default Home;