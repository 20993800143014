import { useEffect, useRef, useState } from "react";
import { Form, OverlayTrigger, Row, Table, Tooltip } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { exceptionHandling } from "../Common/CommonComponents";
import { botApis } from "../services/botApis";


const NewToken = ({ maskAddress, copyTokenAddress, copied, convertUtcToLocal }) => {
    const [newTokenAddressList, setNewTokenAddressList] = useState([])
    const [newTokenStatus, setNewTokenStatus] = useState(true)
    const newTokenAddressRef = useRef(null);
    const [nextPage, setNextPage] = useState(0);
    useEffect(() => {
        getNewTokenAddress(nextPage, "TOP")
    }, [])

    const getNewTokenAddress = async (page, callType) => {
        try {
            const response = await botApis.getNewTokenAddressLog(page);
            if (response.status === 200) {
                let responseData = response.data.data;
                if (callType == "BOTTOM") {
                    let latestTokensTemp = [...newTokenAddressList?.tokens];
                    latestTokensTemp.push(...responseData?.tokens);
                    responseData.tokens = latestTokensTemp;
                }
                if (callType == "TOP") {
                    setNextPage(0)
                }
                setNewTokenAddressList(responseData)
                setNewTokenStatus(false)
            } else {
                throw new Error('Failed to fetch data');
            }
        } catch (error) {
            exceptionHandling(error);
        }
    }

    const onScrollCopyTokenAddress = async () => {
        if (newTokenAddressRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = newTokenAddressRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                let page = nextPage + 1, totalPages = Number(newTokenAddressList.totalPages);
                if (page < totalPages) {
                    await getNewTokenAddress(page, "BOTTOM");
                    setNextPage(page);
                }
            }
        }
    };

    const tokentokensObserverTop = useRef();
    const isCallObserverTopRef = useRef(false);
    const [isCallObserverTop, setIsCallObserverTop] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setIsCallObserverTop(false);
            isCallObserverTopRef.current = false;
        }, 5000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        tokentokensObserverTop.current = new IntersectionObserver(handleTokentokensObserverTop, {
            root: null,
            rootMargin: '20px',
            threshold: 1.0,
        });
        if (newTokenAddressList?.tokens?.length > 0) {
            tokentokensObserverTop.current.observe(document.querySelector('.latest-tokens-tokens-top'));
        }

    }, [isCallObserverTop, isCallObserverTopRef.current, newTokenAddressList, newTokenAddressList?.tokens]);

    const handleTokentokensObserverTop = async (entities) => {
        const target = entities[0];
        if (target.isIntersecting && !isCallObserverTopRef.current) {
            isCallObserverTopRef.current = true;
            setIsCallObserverTop(true);
            await getNewTokenAddress(0, "TOP");
        }
    };

    return (<>
        <div className="transaction-tokens transaction-logs transaction-body token-mis-area all-tokens" ref={newTokenAddressRef} onScroll={onScrollCopyTokenAddress}>
            <Table>
                <thead>
                    <tr>
                        <th>Token Address</th>
                        <th>Balance</th>
                        <th>Honeypot</th>
                        <th>Pool</th>
                        <th>Lp Lock</th>
                        <th>Lp</th>
                        <th>Pool Id</th>
                        <th>Create At</th>
                    </tr>
                </thead>
                <tbody>
                    <div className="latest-tokens-tokens-top"></div>
                    {
                        newTokenStatus == true
                            ? (<tr>
                                <td className="token-front "><Skeleton height={15} width={100} count={20} /></td>
                                <td><Skeleton height={15} width={50} count={20} /></td>
                                <td><Skeleton height={15} width={50} count={20} /></td>
                                <td><Skeleton height={15} width={50} count={20} /></td>
                                <td><Skeleton height={15} width={50} count={20} /></td>
                                <td><Skeleton height={15} width={50} count={20} /></td>
                                <td><Skeleton height={15} width={50} count={20} /></td>
                                <td className="token-front score-board-right"><Skeleton height={15} width={50} count={20} /></td>
                            </tr>) :
                            newTokenAddressList?.tokens?.length > 0 ?
                                newTokenAddressList?.tokens?.map((message, index) => {

                                    function getStatus(status,text) {
                                        if (status == undefined) {
                                            return (<td >-</td>)
                                        } else {
                                            return (<td className={status ? "buy-action" : text ? "": "error"} >{status ? "PASS" : text ? text: "FAIL"}</td>)
                                        }

                                    }
                                    return (<tr className={message.is_latest ? "latest_token" : ""}>
                                        <td className="token-front">{maskAddress(message.address, 5)}
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-${index}`}>{copied[`latestToken${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                            >
                                                <i class="far fa-copy" onClick={() => copyTokenAddress(message.address, `latestToken${index}`)}
                                                ></i>
                                            </OverlayTrigger>
                                        </td>
                                        {getStatus(message?.balance_status)}
                                        {getStatus(message?.honeypot_status)}
                                        {getStatus(message?.pool_status,"N/A")}
                                        {getStatus(message?.lp_lock_status)}
                                        {getStatus(message?.lp_status)}

                                        <td className="token-front">{message?.pool_id ? maskAddress(message.pool_id, 5):"-"}
                                            {message?.pool_id ? <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id={`tooltip-${index}`}>{copied[`poolLatestToken${index}`] ? "Copied" : "Copy to Clipboard"}</Tooltip>}
                                            >
                                                <i class="far fa-copy" onClick={() => copyTokenAddress(message.pool_id, `poolLatestToken${index}`)}
                                                ></i>
                                            </OverlayTrigger> :""}
                                        </td>
                                        <td>{convertUtcToLocal(message.createdAt)}</td>
                                    </tr>)
                                }) :
                                <>
                                    <tr>
                                        <td colSpan={6}>
                                            <div className="no-transaction-area">
                                                <img src={require("../assets/images/no-data.png")} alt="img" />
                                                <p>No token log available.</p>
                                            </div>
                                        </td>
                                    </tr>

                                </>
                    }
                </tbody>
            </Table>
        </div>
    </>)
}
export default NewToken;