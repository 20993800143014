/* local Url */
// const baseUrl ="http://192.168.2.182:8081";

/* for live */
// const baseUrl ="https://mempoolsolanatradingbotapi.netset-software.com"
const baseUrl ="https://tradingbotmatt-api.netsetsoftware.pro";


export const config = {
    apiUrl: `${baseUrl}/api/v1`,
    imageUrl: baseUrl + '/',
}

export const SOL_ADDRESS = "So11111111111111111111111111111111111111112";